const DEV_DOMAIN = 'http://localhost:8000/';

let API_SERVER_VAL = DEV_DOMAIN;

switch (process.env.NODE_ENV) {
  case 'development':
    API_SERVER_VAL = DEV_DOMAIN;
    break;
  case 'production':
    API_SERVER_VAL = process.env.REACT_APP_API_SERVER || '';
    break;
  default:
    API_SERVER_VAL = DEV_DOMAIN;
    break;
}

export const api = {
  base: {
    domain: API_SERVER_VAL,
    url: API_SERVER_VAL + 'api/',
  },
  auth: {
    login: 'auth/login/',
    logout: 'auth/logout/',
    register: 'auth/register/',
    password: 'auth/set_password/',
    reset_password: 'auth/reset_password/',
  },
  user: 'auth/user/',
  org: {
    base: 'org/',
    by_id: (org_id: number) => `org/${org_id}/`,
  },
  surveys: {
    base: 'surveys/',
    all: {
      by_org_id: (org_id: number) => `surveys/${org_id}/`,
    },
    survey_type: {
      by_org_id: (survey_type: string, org_id: number) => `${survey_type}/surveys/${org_id}/`,
    },
  },
};

export const app = {
  marketing: '/',
  login: '/login',
  register: '/register',
  org_select: '/org_select',
  set_password: '/set_password',
  base: {
    url: '/app',
    surveys: {
      base: '/app/surveys',
      organization: '/app/surveys/organization',
      plan_design: '/app/surveys/plandesign',
      performance: '/app/surveys/performance',
    },
    charts: {
      base: '/app/charts',
      organization: '/app/charts/organization',
      plan_design: '/app/charts/plandesign',
      performance: '/app/charts/performance',
    },
  },
};

const routes = { api, app };

export default routes;
