import { Flex, FlexProps, Heading, HStack, Text, useDisclosure } from '@chakra-ui/react';

import { HowDoesItWorkModal } from './HowDoesItWorkModal';
import { SurveysTable } from './SurveysTable';
import { WhatAreBenchmarksModal } from './WhatAreBenchmarksModal';

const HomeContent = (props: FlexProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBenchmarksModalOpen,
    onOpen: onBenchmarksModalOpen,
    onClose: onBenchmarksModalClose,
  } = useDisclosure();

  return (
    <Flex direction="column" width="100%" px={20} {...props}>
      <Heading size="lg" fontWeight="bold" onClick={onBenchmarksModalOpen} cursor="pointer">
        Benchmark Data
      </Heading>
      <HStack py={3}>
        <Text fontWeight="normal">
          This is a list of your data submissions and their status. Once a data set is complete, you are able to access
          the related benchmarks.
        </Text>
        <Text color="primary.400" onClick={onOpen} cursor="pointer">
          How does it works?
        </Text>
      </HStack>
      <SurveysTable my={3} />
      <HowDoesItWorkModal isOpen={isOpen} isCentered={true} onClose={onClose} children={null} />
      <WhatAreBenchmarksModal
        isOpen={isBenchmarksModalOpen}
        isCentered={true}
        onClose={onBenchmarksModalClose}
        children={null}
      />
    </Flex>
  );
};

export default HomeContent;
