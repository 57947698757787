import { Stack, VStack } from '@chakra-ui/react';
import React from 'react';

import ChartFiltersBar from '../ChartFiltersBar';
import OG01 from './OG01';
import OG02 from './OG02';
import OG03 from './OG03';
import OG04 from './OG04';
import OG05 from './OG05';
import OG06 from './OG06';
import OG07 from './OG07';
import OG08 from './OG08';
import OG09 from './OG09';
import OG10 from './OG10';
import PL01 from './PL01';
import PL02 from './PL02';
import PL03 from './PL03';
import PL05 from './PL05';
import PL06 from './PL06';
import PL07 from './PL07';
import PL08 from './PL08';
import PL09 from './PL09';
import PL12 from './PL12';
import PL13 from './PL13';
import PL14 from './PL14';
import PL15 from './PL15';
import PY01 from './PY01';
import PY03 from './PY03';
import PY04 from './PY04';
import PY05 from './PY05';
import PY06 from './PY06';
import PY07 from './PY07';
import PY08 from './PY08';

interface ChartListProps {
  survey_type: 'org' | 'plan' | 'payee';
}

const orgCharts = [
  <OG01 key={`chart-og-01-key`} />,
  <OG02 key={`chart-og-02-key`} />,
  <OG03 key={`chart-og-03-key`} />,
  <OG04 key={`chart-og-04-key`} />,
  <OG05 key={`chart-og-05-key`} />,
  <OG06 key={`chart-og-06-key`} />,
  <OG07 key={`chart-og-07-key`} />,
  <OG08 key={`chart-og-08-key`} />,
  <OG09 key={`chart-og-09-key`} />,
  <OG10 key={`chart-og-10-key`} />,
];
const planCharts = [
  <PL01 key={`chart-pl-01-key`} />,
  <PL02 key={`chart-pl-02-key`} />,
  <PL03 key={`chart-pl-03-key`} />,
  <PL05 key={`chart-pl-05-key`} />,
  <PL06 key={`chart-pl-06-key`} />,
  <PL07 key={`chart-pl-07-key`} />,
  <PL08 key={`chart-pl-08-key`} />,
  <PL09 key={`chart-pl-09-key`} />,
  <PL12 key={`chart-pl-12-key`} />,
  <PL13 key={`chart-pl-13-key`} />,
  <PL14 key={`chart-pl-14-key`} />,
  <PL15 key={`chart-pl-15-key`} />,
];
const payeeCharts = [
  <PY01 key={`chart-py-01-key`} />,
  <PY03 key={`chart-py-03-key`} />,
  <PY04 key={`chart-py-04-key`} />,
  <PY05 key={`chart-py-05-key`} />,
  <PY06 key={`chart-py-06-key`} />,
  <PY07 key={`chart-py-07-key`} />,
  <PY08 key={`chart-py-08-key`} />,
];

const charts = {
  org: orgCharts,
  plan: planCharts,
  payee: payeeCharts,
};

const ChartList: React.FC<ChartListProps> = ({ survey_type }) => {
  return (
    <VStack spacing={4} align="center">
      <Stack spacing={10} position={'sticky'} top={0} zIndex={1000} bg={'white'} width={'80%'} py={2} direction={'row'}>
        <ChartFiltersBar />
      </Stack>
      {charts[survey_type].map((chart) => chart)}
    </VStack>
  );
};

export default ChartList;
