import { Box, Flex, Heading, StackProps, VStack } from '@chakra-ui/react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Organization, Survey } from '@/types';
import ChartList from '../components/charts/ChartList';
import { useStickyState, useSurvey } from '../hooks';
import { toSurveyTitle } from '../utils';

interface ChartListPageProps extends StackProps {
  title?: string;
}

const ChartListPage: React.FC<ChartListPageProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const survey_type = searchParams.get('survey_type');
  const [org] = useStickyState<Organization>(null, 'org');
  const [survey, setSurvey] = useState<Survey>(location.state?.survey);
  const { data: surveys, isSuccess } = useSurvey(survey_type, org?.id);

  useEffect(() => {
    if (!survey_type || !['org', 'plan', 'payee'].includes(survey_type)) navigate(-1);
    if (!survey && isSuccess) {
      const latestSurvey = surveys?.reduce((prev, curr) => {
        return new Date(prev.effective_at) > new Date(curr.effective_at) ? prev : curr;
      });
      setSurvey(latestSurvey);
      const newLocation = {
        ...location,
        state: {
          ...(location.state || {}),
          survey: latestSurvey,
          survey_type: latestSurvey.survey_type,
        },
      };
      navigate(newLocation, { replace: true });
    }
  }, [survey_type, survey, surveys, isSuccess, location, navigate]);

  if (!survey) return null;

  return (
    <VStack spacing={4} maxHeight={'100vh'} overflow="scroll">
      <Flex direction="column">
        <Box onClick={() => navigate(-1)} _hover={{ cursor: 'pointer' }} pt={10}>
          <FontAwesomeIcon icon={icon({ name: 'arrow-left' })} />
          <Heading size="sm" fontWeight="medium" as="span" px={3}>
            Back
          </Heading>
        </Box>
        <Heading size="xl" fontWeight="medium" borderBottomWidth={2} py={5} borderColor="gray.100">
          {toSurveyTitle(survey, org.name)}
        </Heading>
      </Flex>
      <ChartList survey_type={survey.survey_type} />
    </VStack>
  );
};

export default ChartListPage;
