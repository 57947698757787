import { Organization, Survey } from '@/types';
import {
  Button,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  TableContainerProps,
  Tag,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAllSurveys, useStickyState } from '../hooks';
import routes from '../routes';

export const SurveysTable = (props: TableContainerProps) => {
  const navigate = useNavigate();
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [org] = useStickyState<Organization>(null, 'org');
  const { data: surveys, isLoading, isError, isRefetchError, isFetching } = useAllSurveys(org?.id);
  const surveyNames = {
    org: 'Organization',
    plan: 'Plan Design',
    payee: 'Performance',
  };

  useEffect(() => {
    if (survey?.is_accepted) {
      navigate(`${routes.app.base.charts.base}?survey_type=${survey.survey_type}`, {
        state: { survey: survey },
      });
    }
    return () => setSurvey(null);
  }, [navigate, survey]);

  if (isLoading || isFetching) return <Heading>Loading...</Heading>;
  if (isError || isRefetchError) return <Heading>Error loading data</Heading>;
  if (!surveys) return <Heading>No Surveys found</Heading>;

  return (
    <TableContainer {...props}>
      <Table variant="simple">
        <TableCaption></TableCaption>
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Time period</Th>
            <Th>as of</Th>
            <Th>status</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {surveys.map((survey) => (
            <Tr key={`key-survey-${survey.id}`}>
              <Td>{surveyNames[survey.survey_type]}</Td>
              <Td>{survey.survey_period}</Td>
              <Td>{new Date(survey.effective_at).toDateString()}</Td>
              <Td>
                {survey.is_accepted ? (
                  <Tag size="lg" width="110px" backgroundColor="positive.50" textColor="positive.600">
                    Completed
                  </Tag>
                ) : (
                  <Tag size="lg" width="70px" backgroundColor="gray.200">
                    Open
                  </Tag>
                )}
              </Td>
              <Td>
                {survey.is_accepted ? (
                  <Button fontWeight="normal" onClick={() => setSurvey(survey)}>
                    See Benchmarks
                  </Button>
                ) : (
                  <Button variant="outline" fontWeight="normal" onClick={() => {}} disabled={true}>
                    Awaiting Data
                  </Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot></Tfoot>
      </Table>
    </TableContainer>
  );
};
