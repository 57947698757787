import { Box, Flex, Heading, SkeletonCircle, SkeletonText, Stack, StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import NoDataChart from './NoDataChart';

interface BaseChartProps extends StackProps {
  title: string;
  subtitle: string;
  description: string;
  isLoading?: boolean;
  hiddenDefinition?: boolean;
  error?: boolean;
}

const LoadingChart = () => (
  <Box padding="6" boxShadow="lg" bg="white" width={600} height={300}>
    <SkeletonCircle size="10" />
    <SkeletonText mt="4" noOfLines={8} spacing="4" skeletonHeight="2" />
  </Box>
);

const BaseChart: React.FC<BaseChartProps> = (props) => {
  const { title, subtitle, description, children, isLoading, error } = props;
  const errorDescription =
    "We're having problems populating this chart. If this issue persists, please contact us at support@captivateiq.com";
  let chart = null;

  switch (true) {
    case isLoading:
      chart = <LoadingChart />;
      break;
    case error:
      chart = <NoDataChart title="Error" description={errorDescription} />;
      break;
    default:
      chart = children;
  }

  return (
    <Stack spacing={4} align={'center'} py={'16px'} borderBottom={'2px'} borderColor={'gray.100'} width={600}>
      <Flex align={'flex-start'} direction={'column'}>
        <Text color="gray.500" casing={'uppercase'} my={2}>
          {subtitle}
        </Text>
        <Heading size="lg" wordBreak={'break-word'}>
          {title}
        </Heading>
      </Flex>
      {chart}
      <Box borderRadius="md" bg="gray.100" width={'600px'} hidden={props.hiddenDefinition}>
        <Stack spacing={4} p={6}>
          <Heading size="sm">{'Definition'}</Heading>
          <Text>{description}</Text>
        </Stack>
      </Box>
    </Stack>
  );
};

export default BaseChart;
